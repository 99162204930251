import {environment} from '../../environments/environment';
import {DBC_BACKGROUND_TYPE, default_contact_info_ordering} from './digital-business-card.model';
import {
    getDefaultForBio,
    getDefaultForButton,
    getDefaultForCompanyDetails,
    getDefaultForContactDetails,
    getDefaultForPersonalInfo
} from '../shared/utils';
import {CONTACT_DETAIL} from './digital-business-card-utils';

function getContactInfoOrdering(layout: string) {
    switch (layout) {
        case '8':
            return {
                '0': CONTACT_DETAIL.CUSTOM_FIELDS,
                '1': CONTACT_DETAIL.EMAIL_V2,
            };
        default:
            return default_contact_info_ordering;
    }
}

const commonSocialLinks = {
    facebook: 'https://www.facebook.com/username',
    instagram: 'https://www.instagram.com/username',
    twitter: 'https://x.com/username',
    linkedin: 'https://www.linkedin.com/username',
}
function getCommonSocialLinks(layout: string) {
    switch (layout) {
        case '9':
            return {
                facebook: 'https://www.facebook.com/username',
                twitter: 'https://x.com/username',
                instagram: 'https://www.instagram.com/username',
                whatsapp: 'https://wa.me/phone_number',
            };
        default:
            return commonSocialLinks;
    }
}

function getTypography(layout: string) {
    const commonTypography = {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Medium',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio: {
            google_font_style: 'Regular',
        },
    };

    switch (layout) {
        case '1':
        case '2':
            return commonTypography; // No changes from common
        case '3':
            return {
                ...commonTypography,
                personal_info: {
                    ...commonTypography.personal_info,
                    google_font_style: 'Medium',
                },
                company_details: {
                    google_font_style: 'Light',
                },
                contact_details: {
                    google_font_style: 'Regular',
                }
            };
        case '4':
        case '5':
        case '6':
        case '9':
            return {
                ...commonTypography,
                contact_details: {
                    google_font_style: 'Semi Bold',
                },
            };
        case '11':
            return {
                ...commonTypography,
                font_family: 'Atkinson Hyperlegible',
                personal_info: {
                    google_font_style: 'Bold',
                    google_font_size: 24,
                },
                contact_details: {
                    google_font_style: 'Regular',
                }
            };
        default:
            return commonTypography; // Return common as fallback
    }
}

export const typographyLayouts = {
    '1': getTypography('1'),
    '2': getTypography('2'),
    '3': getTypography('3'),
    '4': getTypography('4'),
    '5': getTypography('5'),
    '6': getTypography('6'),
    '9': getTypography('9'),
    '11': getTypography('11')
};

// Template mapping with their names
export const TEMPLATE_NAMES_MAP = {
    1: 'Brisk_Motors',
    2: 'Murdoch_Associates',
    3: 'McGee_Security_Corp',
    4: 'Schoen_&_Co.',
    5: 'Eurus_Sports',
    6: 'AIB_Global',
    7: 'Salford_Health',
    8: 'Onama_Tech',
    9: 'Harzone',
    10: 'Cinco',
    11: 'Anakin'
}

const commonCustomizationAttributes = {
    font_type: 'google',
    custom_font_url: '',
    custom_font_style: '',
    profile_info: 'Bold',
    company_details: 'Regular',
    contact_details: 'Medium',
    button: 'Bold'
}

function getTypographyDefaults(layout: string) {
    return {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: getDefaultForPersonalInfo(layout),
        company_details: getDefaultForCompanyDetails(layout),
        contact_details: getDefaultForContactDetails(layout),
        button: getDefaultForButton(layout),
        bio: getDefaultForBio(layout),
    };
}

export const DBC_LAYOUTS = [
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_1.svg?v=1.2`,
        name: '1',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/max.png',
        first_name: 'Max',
        last_name: 'McGonagall',
        prefix: '',
        designation: 'Automotive Engineer',
        company: 'Brisk Motors',
        summary: 'I handle production and maintenance of our new line of electric cars here at Brisk Motors.',
        user_image_url: `${environment.imagesEndpoint}/dbc/max.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/38025/f3e3a2887c2d4f8fb36cea3578bcc320?v=1719468051.85683',
        pronouns_v2: 'he/him',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'max.m@briskmotors.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Personal',
                'value': '(406) 555-0120',
                'valid': 'valid',
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'www.briskmotors.com'
            }
        ],
        social_links: commonSocialLinks,
        logo_size: 90,
        customizations: {
            background_color: '#5e53a3',
            user_info_color: '#000000',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 30,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('1'),
            button_color: '#5e53a3',
            icon_color: '#5e53a3',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_2.svg?v=1.2`,
        name: '2',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/hermoine.png',
        first_name: 'Hermoine',
        last_name: 'Granger',
        prefix: '',
        designation: 'Paralegal',
        pronoun: 'she/her',
        company: 'Murdoch & Associates',
        summary: '',
        user_image_url: `${environment.imagesEndpoint}/dbc/hermoine.png`,
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_2_logo.svg`,
        pronouns_v2: 'she/her',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'hermoine@murdoch.com'
            }
        ],
        phone_v2: [
            {
                'value': '(406) 555-0120',
                'label': 'Personal',
                'valid': 'valid'
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'www.murdoch.com'
            }
        ],
        social_links: commonSocialLinks,
        address_v2: 'Murdoch & Associates Office, East MR Road, Ohio, USA',
        logo_size: 60,
        customizations: {
            background_color: '#3370f9',
            user_info_color: '#000000',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 30,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('2'),
            button_color: '#3370f9',
            icon_color: '#3370f9',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_11.svg?v=1.1`,
        name: '11',
        profile_photo: '',
        first_name: 'Darell',
        last_name: 'Blanchard',
        prefix: '',
        designation: 'Education Director',
        pronoun: '(they/them)',
        company: 'Anakin Pedagogical Institute',
        summary: 'Passionate about shaping future educators and innovative teaching methods, I enjoy reading and gardening in my free time.',
        user_image_url: '',
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/38025/67b9b86e8f2a419c93deb3642f914d7f?v=1719434970.14374',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'darell.b@anakin.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Phone',
                'valid': 'valid',
                'value': '(406) 555-0120'
            }
        ],
        website_v2: [
            {
                'label': 'company',
                'valid': 'valid',
                'value': 'www.anakinpedagogic.com'
            }
        ],
        pronouns_v2: 'they/them',
        social_links: commonSocialLinks,
        address_v2: '104 Pine Avenue Springfield, SP 56789 United States',
        customizations: {
            background_color: '#fbdf57',
            user_info_color: '#323232',
            secondary_color: '#323232',
            font_style: 'Roboto',
            title_font_size: 30,
            ...commonCustomizationAttributes,
            typography: {
                font_type: 'google',
                font_family: 'Atkinson Hyperlegible',
                personal_info: getDefaultForPersonalInfo('11'),
                company_details: getDefaultForCompanyDetails('11'),
                contact_details: getDefaultForContactDetails('11'),
                button: getDefaultForButton('11'),
                bio: getDefaultForBio('11'),
            },
            button_color: '#323232',
            icon_color: '#323232',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_3.svg?v=1.1`,
        name: '3',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/janet.jpeg',
        first_name: 'Janet',
        last_name: 'McGee',
        prefix: '',
        designation: 'Founder, CEO',
        company: 'McGee Security Corp.',
        summary: 'Hi! I head one of the best cybersecurity firms in the US. Get in touch.',
        user_image_url: `${environment.imagesEndpoint}/dbc/janet.jpeg`,
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_3_logo.svg`,
        pronouns_v2: 'she/her',
        address_v2: 'Murdoch & Associates Office, East MR Road, Ohio, USA',
        address_url: 'https://www.google.com/maps/search/?api=1&query=40.7563205,-73.9782735&query_place_id=ChIJseL_Zf5YwokRZP8hmu0Iryk',
        email_v2 : [
            {
                label: 'Personal',
                valid: 'valid',
                value: 'janet@mcgee.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Mobile',
                'value': '(406) 555-0120',
                'valid': 'valid'
            },
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '(406) 677-0246'
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'https://mcgee.com'
            }
        ],
        social_links: commonSocialLinks,

        customizations: {
            background_color: '#252a7c',
            user_info_color: '#FFFFFF',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 30,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('3'),
            button_color: '#252a7c',
            icon_color: '#252a7c',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_6.svg?v=1.1`,
        name: '6',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/joe.png',
        first_name: 'Joe',
        last_name: 'Tribianni',
        prefix: '',
        designation: 'CTO',
        pronoun: 'he/him',
        company: 'AIB Global Business Services',
        summary: 'I lead product across teams for AIB and I ski in my free time',
        user_image_url: `${environment.imagesEndpoint}/dbc/joe.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/1314632af1fe443a8528e8ffabde5dbf',
        pronouns_v2: 'he/him',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'joe@aibglobal.com'
            },
        ],
        'phone_v2': [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '(406) 677-0246'
            }
        ],
        address_v2: '301, Peter’s Cote, 3rd Avenue St., Sunnyville, California',
        social_links : commonSocialLinks,
        customizations: {
            background_color: '#1F70C1',
            user_info_color: '#000000',
            secondary_color: '#000000',
            font_style: 'Open Sans',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('6'),
            button_color: '#1F70C1',
            icon_color: '#1F70C1',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_5.svg?v=1.1`,
        name: '5',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/james.png',
        first_name: 'James',
        last_name: 'McFinnigan',
        prefix: '',
        designation: 'Product Lead',
        pronoun: 'he/him',
        company: 'Eurus Sports',
        summary: 'I lead product across teams for Eurus and I ski in my free time. I love to work on AR/VR and have created product strategies for the same.\n\nHit me up for a drink!',
        user_image_url: `${environment.imagesEndpoint}/dbc/james.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/c66faea4cdd345199e411d3cd4376f09',
        pronouns_v2: 'he/him',
        address_v2: '301, Peter’s Cote, 3rd Avenue St., Sunny-ville, California.',
        social_links : commonSocialLinks,
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '(406) 555-0120'
            }
        ],
        'email_v2' : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'james@eurus.design'
            },
        ],
        customizations: {
            background_color: '#EE4F4F',
            user_info_color: '#FFFFFF',
            secondary_color: '#000000',
            font_style: 'Open Sans',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('5'),
            button_color: '#EE4F4F',
            icon_color: '#EE4F4F',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_4.svg?v=1.1`,
        name: '4',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/ashley.png',
        first_name: 'Ashley James',
        last_name: 'Fletcher',
        prefix: 'Dr.',
        designation: 'CXO, Founding Designer',
        pronoun: 'she/her',
        company: 'Schoen & Co.',
        summary: 'I lead design across teams for Schoen & Co. and it\'s subsidiaries.',
        user_image_url: `${environment.imagesEndpoint}/dbc/ashley.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/880f4af73be04f2a8f48270d7998f02f?v=1719433098.232262',
        pronouns_v2: 'she/her',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'ash@schoenco.design'
            },
            {
                label: 'Personal',
                valid: 'valid',
                value: 'ashleyrocks@gmail.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '(406) 555-0120'
            }
        ],
        address_v2: ' 301, Peter’s Cote, 3rd Avenue St., Sunnyville, California',
        social_links : commonSocialLinks,
        customizations: {
            background_color: '#FF9900',
            user_info_color: '#000000',
            secondary_color: '#000000',
            font_style: 'Open Sans',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('4'),
            button_color: '#FF9900',
            icon_color: '#FF9900',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_9.svg?v=1.1`,
        name: '9',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/ashley.png',
        first_name: 'Machael',
        last_name: 'Scott',
        prefix: 'Dr.',
        designation: 'Regional Manager',
        pronoun: 'he/him',
        company: 'Harzone Logistics Inc.',
        summary: 'I work on optimizing supply chain operations at Harzone. Outside of work, I enjoy cycling and cooking gourmet meals.',
        user_image_url: '',
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/fa838a2bbb5e4928b01e19152752adfd?v=1719294908.418258',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'm.scott@harzone.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Phone',
                'valid': 'valid',
                'value': '(406) 555-0120'
            }
        ],
        website_v2: [
            {
                'label': 'Company',
                'valid': 'valid',
                'value': 'www.harzone.com'
            }
        ],
        social_links : getCommonSocialLinks('9'),
        pronouns_v2: 'he/him',
        department: 'Scranton branch',
        customizations: {
            background_color: '#1c1919',
            user_info_color: '#FFFFFF',
            secondary_color: '#FFFFFF',
            font_style: 'Open Sans',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('9'),
            button_color: '#ffffff',
            icon_color: '#ffffff',
            background: {
                type: DBC_BACKGROUND_TYPE.Image,
                value: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/480643ba68ab42ed96baf3f45f1324d1'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_8.svg?v=1.1`,
        name: '8',
        profile_photo: '',
        first_name: 'Katy',
        last_name: 'Hartman',
        prefix: '',
        designation: 'UX Researcher',
        pronouns_v2: 'she/her',
        company: 'Onama Tech LLC',
        'department': 'Research Department',
        summary: 'I work on enhancing user experiences. When I\'m not delving into user insights, you\'ll find me exploring hiking trails.',
        email_v2: [{
            label: 'Work',
            value: 'k.hartman@onama.com',
            valid: true
        }],
        custom_fields: [{
            label: 'Employee ID',
            value: '00001234-56',
            valid: true
        }],
        address_v2: '922 East Portertown, West Virginia 82571',
        social_links: commonSocialLinks,
        lead_collection: true,
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_8_logo.svg`,
        logo_size: 60,
        customizations: {
            background_color: '#3D7ED9',
            user_info_color: '#FFFFFF',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('8'),
            button_color: '#3D7ED9',
            icon_color: '#3D7ED9',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: getContactInfoOrdering('8')
    },
    {
        imageAddress: `${environment.imagesEndpoint}dbc/layouts/layout_7.svg?v=1.1`,
        name: '7',
        profile_photo: 'https://beaconstacqa.mobstac.com/static/images/dbc/janet.jpeg',
        prefix: 'Dr.',
        first_name: 'Saul',
        last_name: 'Mcmillan',
        pronouns_v2: 'pronouns',
        designation: 'Family Physician',
        company: 'Stanford Health',
        summary: 'I aim to provide personalized healthcare for all my patients, fostering relationships built on trust and expertise.',
        user_image_url: `${environment.imagesEndpoint}/dbc/profile-img/layout-7-profile.jpg`,
        phone_v2: [{
            label: 'Work',
            value: '(406) 555-0126',
            valid: true
        }],
        email_v2: [{
            label: 'Personal',
            value: 'dr.saulmcmillan@gmail.com',
            valid: true
        }],
        website_v2: [{
            label: 'Work',
            value: 'www.salford.com',
            valid: true
        }],
        address_v2: '7701 Chris Allee 5C, Niedersachsen',
        social_links: commonSocialLinks,
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_7_logo.svg`,
        logo_size: 90,
        customizations: {
            background_color: '#ED773A',
            user_info_color: '#266E49',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 24,
            ...commonCustomizationAttributes,
            typography: getTypographyDefaults('7'),
            button_color: '#266E49',
            icon_color: '#266E49',
            background: {
                type: DBC_BACKGROUND_TYPE.Color,
                value: '#FFFFFF'
            }
        },
        contact_info_ordering: default_contact_info_ordering,
    },
];
